import React, {useState} from "react";
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col, Button} from "react-bootstrap";
import CtaBg  from "../../images/cta_bg.jpg";
import BookAViewing from "../Forms/BookAViewing";
import Modal from 'react-bootstrap/Modal';
import "./ctaBlock.scss";
import PropertyContact from "../Forms/PropertyContact";
const CtaBlock = (props) => {
    const [showForm, setShowForm] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const propertyAddress=props?.propertyData?.display_address
    return(
<section className="propery-cta-component" style={{ backgroundImage: `url(${CtaBg})` }}>
<Container>
   
    <Row>
        <Col lg={12}>
            <h2>are you interested in this property?</h2>
            <div className="d-block d-md-flex align-items-center justify-content-center btn-bk">
            <Button onClick={()=> setShowForm(true)} className="btn outline">request a viewing</Button>
            <Button onClick={()=> setShowModal(true)} className="btn outline">email us</Button>
            </div>
            
                 </Col>
                 </Row>
    </Container>
    <Modal className="teamdetails popupForm" backdrop="static" size="lg" show={showForm} onHide={() => { setShowForm(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>request a viewing</Modal.Title>
            </Modal.Header>
            <Modal.Body> 
                <BookAViewing propertyData={props?.propertyData}/>   
            </Modal.Body>

    </Modal>
    <Modal className="teamdetails popupForm" backdrop="static" size="lg" show={showModal} onHide={() => { setShowModal(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>Email Us</Modal.Title>
            </Modal.Header>
            <Modal.Body> 
                <PropertyContact propertyAddress={propertyAddress}/>   
            </Modal.Body>

    </Modal>
    </section>
)
    }
export default CtaBlock
