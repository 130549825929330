import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Container, Row, Col} from "react-bootstrap";
import "./similarProperties.scss";
import axios from "axios"
import FeaturedPropertiesImg1  from "../../images/featured-properties-img1.jpg";
import FeaturedPropertiesImg2  from "../../images/featured-properties-img2.jpg";
import FeaturedPropertiesImg3  from "../../images/featured-properties-img3.jpg";
import FeaturedPropertiesImg4  from "../../images/featured-properties-img4.jpg";
import ImageTransform from "../../components/Common/ggfx-client/module/components/image-transform"
// import imageConfig from "../../../static/images/config.json"
import {FavIcon } from "../icons"
// import { Link } from "@reach/router";
import { Link } from "gatsby";
// import UserImg1 from "../../../images/propertydetails/user1.png"
// import UserImg2 from "../../../images/propertydetails/user2.png"
// import UserImg3 from "../../../images/propertydetails/user3.png"


const SimilarProperties = (props) => {

    const [similarProp,setSimilarProp]=useState([])

    const getitems = async url => {
        try {
          const { data } = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
            }})
          setSimilarProp(data)
        } catch (error) {
          // console.error(error)
        }
      }

    useEffect(()=>{
        let url =process.env.GATSBY_STRAPI_SRC +
        "stb-lists/item/Property-details?pid=" +
        props?.propertyData?.id
        getitems(url)

    },[])
   

    const settings = {
        dots: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite:similarProp.length>3?true:false,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  infinite: true,
                  dots:true,
                },
              },
            {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite: true,
                  dots:true,
                },
              },
            {
                breakpoint: 991,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite: true,
                  dots:true,
                },
              },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots:true,
            },
          },
        ],
      }

    return (similarProp?.length > 0 &&
        <React.Fragment>
            <section className="similar-properties">
               <Container>
                   <Row>
                       <Col lg={12}>
                           <h2>similar properties</h2>
                           </Col>
                   </Row>
                   <Row className="feature-properties-row">
                       <Col lg={12}>
                       {similarProp?.length > 0 &&
                            <Slider {...settings}>
                            {similarProp?.map((item, index) =>{
                              let processedImages = JSON.stringify({})
                              if (item?.imagetransforms?.images_Transforms) {
                                processedImages = item.imagetransforms.images_Transforms
                              }

                              let uriStr="property-for-sale/"

                              let address1=item.display_address?.split(",");
                              address1.pop();
                              address1.pop();
                              let displayAddress1=address1.join();

                              return(                                
                                <div className="similar-property-card-main">
                                    <div className="similar-property-img-content">
                                      <Link to={`/${uriStr}${item.slug}-${item.id}/`}>
                                        <div className="feature-property  -img-wrap img-hover-zoom video-wrap">
                                        <picture>
                                        <ImageTransform
                                          imagesources={item.images[0]?.url}
                                          renderer="srcSet"
                                          imagename="property.images.detail"
                                          attr={{
                                          alt:
                                            item.display_address + " - Crisp Cowley"

                                          }}
                                          imagetransformresult={processedImages}
                                          id={item.id}
                                          testparam={true}
                                           />
                                        </picture>
                                        </div>
                                        </Link>
                                        {/* <span className="tag">UNDER OFFER</span> */}
                                    </div>
                                    <div className="similar-property-text-content">
                                      
                                        <div className="property-description">
                                        <Link to={`/${uriStr}${item.slug}-${item.id}/`}>
                                            <p>{displayAddress1}</p>
                                          </Link>  
                                        </div>
                                        <div className="property-amount-text">
                                        £ {item.price?.toLocaleString()}
                                        </div>
                                      
                                        <div className="bedroom">{item.title}</div>
                                    </div>
                                </div>
                              )
                                
                            })}
                             </Slider>
}
                       </Col>
                   </Row>
               </Container>
            </section>
        </React.Fragment>
    )
}

export default SimilarProperties;