import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
// import $ from "jquery"
import Lightbox from "react-image-lightbox"
import moment from 'moment';
import "react-image-lightbox/style.css"
import { Container, Row, Col, Button } from "react-bootstrap";
import PropertyImg from "../../images/property-img.jpg";
import PImg1 from "../../images/pimg1.jpg";
import PImg2 from "../../images/pimg2.jpg";
import PImg3 from "../../images/pimg3.jpg";
import PImg4 from "../../images/pimg4.jpg";
import ContentBlock from "../Content/content";
import FeaturedPropertiesImg2 from "../../images/featured-properties-img2.jpg";
import FeaturedPropertiesImg3 from "../../images/featured-properties-img3.jpg";
import FeaturedPropertiesImg4 from "../../images/featured-properties-img4.jpg";
import { Back,Bedroom,Bathroom,Reception, CallIcon,playButton } from "../icons";
import ImageTransform from "../../components/Common/ggfx-client/module/components/image-transform"
import "./propertyDetails.scss";
import BookAViewing from "../Forms/BookAViewing";
import Modal from 'react-bootstrap/Modal';
import PropertyContact from "../Forms/PropertyContact";
import { isMobileOnly } from "react-device-detect";
import NoImage from "../../images/no-image.png"
import PlayVideo from "../PlayVideo/PlayVideo";
import PlayVideoNew from "../PlayVideo/PlayVideoNew";



const PropertyDetails = (props) => {
    const [showForm, setShowForm] = useState(false);

  // Brochure images lightbox
  const [brochureIndex, setBrochureIndex] = useState(0)
  const [isOpenBrochure, setIsOpenBrochure] = useState(false)
  const [isPlayVideo, setPlayVideo] = useState(false);
  const [showVideo, setShowVideo]=useState(false)

  const [showpopImage,setPopImage]=useState(false)

  const openBrochureImage = (e, ind) => {
    e.preventDefault()
    setBrochureIndex(ind)
    setIsOpenBrochure(true)
  }

  const brochureplanImages = props?.propertyData?.brochure
  var brochureplanLightImages =
  brochureplanImages && brochureplanImages.map(brochureImg => brochureImg.url)
  // Brochure images lightbox
  // Floorplan images lightbox
  const [floorIndex, setFloorIndex] = useState(0)
  const [isOpenFloor, setIsOpenFloor] = useState(false)

  const openFloorplanImage = (e, ind) => {
    e.preventDefault()
    setFloorIndex(ind)
    setIsOpenFloor(true)
  }

  const trackerVideo = (event) => {
    if (typeof window !== "undefined"){
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Download Brochure',
      'formType': event,
      'formId': 'Property Detail - Download Brochure',
      'formName': 'Download Brochure',
      'formLabel': 'Property Detail - Download Brochure'
    });
  }
}

  const floorplanImages = props?.propertyData?.floorplan
  var floorplanLightImages =
    floorplanImages && floorplanImages.map((floorImg) => {
      if(floorImg?.url) {
        return floorImg.url
      }
      else if(floorImg?.srcUrl) {
        return floorImg.srcUrl
      }
    })
  // Floorplan images lightbox

  // Property images lightbox
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [feature,showFeature]=useState(false);
  const [openVideo, setOpenVideo]=useState(false)

  const openPropertyImage = (e, ind) => {
    e.preventDefault()
    setPhotoIndex(ind)
    setIsOpen(true)
  }


  const showMoreImage = (e, ind) => {
    e.preventDefault()
    setPhotoIndex(ind)
    setPopImage(true)
  }
  // Property images lightbox

  // Property details images lightbox
  const propertyImages = props?.propertyData?.images
  var propertyLightImages =
    propertyImages && propertyImages.map(img => img?.srcUrl)
  // Property details images lightbox

  let processedImages = JSON.stringify({});
  if (props?.propertyData?.imagetransforms?.images_Transforms) {
    processedImages = props.propertyData.imagetransforms.images_Transforms;
  }

  const myRefless = useRef(null)
  const readlessScroll = () => {
    myRefless.current.scrollIntoView()
  }
  const [active, setActive] = useState('Read More');
  const [activeStatus, setActiveStatus] = useState(false);

  const handleAccordionClick = () => {
    if (active === 'Read More') {
      setActive('Read Less')
    } else {
      setActive('Read More')
      readlessScroll()
    }
  }

  const capitalize=(str)=>{
    if(typeof str!=="string") return "";
    return str?.charAt(0).toUpperCase()+str?.slice(1)
  }

  const tenure=props?.propertyData?.extra?.tenure
  const epc = props?.propertyData?.epc;
    let epcUrl = '';
  if (epc?.length > 0) {
    epcUrl = props?.propertyData?.search_type === 'sales' 
      ? (epc[0]?.report_url 
          ? epc[0]?.report_url 
          : epc[0]?.energy_efficiency_current && epc[0]?.energy_efficiency_potential 
            ? `https://www.epcgraph.co.uk/epc.png?${epc[0]?.energy_efficiency_current},${epc[0]?.energy_efficiency_potential},L`
            : ''
        )
      : (
          epc[0]?.url 
            ? epc[0]?.url 
            : ''
        );
  }

  const initialImg=props?.propertyData?.images.slice(0,4);

  const moreImages=props?.propertyData?.images?.length>0&&props?.propertyData?.images.slice(4);
  var showLightImages =
  moreImages && moreImages.map(img => img?.srcUrl)
  const propertyAddress=props?.propertyData?.display_address
  const handleBack = () => {
    window.history.back()
  }

  const day=props?.propertyData?.created;

  const calculateDaysLeft = (startDate, endDate) => {
    if (!moment.isMoment(startDate)) startDate = moment(startDate);
    if (!moment.isMoment(endDate)) endDate = moment(endDate);

    return endDate.diff(startDate, "days");
  }

  const daysLeft = calculateDaysLeft(day, new Date());

const bathroom=props?.propertyData?.bathroom;
const bedroom=props?.propertyData?.bedroom;
const reception=props?.propertyData?.reception;

const keyFeatures= props?.propertyData?.search_type === 'sales' ? props?.propertyData?.accomadation_summary : props?.propertyData?.description?.split(" * ");
const videoUrl=props?.propertyData.video_tour.length>0&&props?.propertyData.video_tour[0].url;

const videoHandler=(e)=>{
    e.preventDefault()
  setPlayVideo(true);
  setShowVideo(true)
}

useEffect(()=>{
  if(props?.propertyData?.long_description?.length > 750) setActiveStatus(true)
},[])

  return (
    <section className="propery-detail-component">
      <Container>

        <Row>
          <Col lg={12}>
            <Row className="d-flex first-row">
              <Col md={{ span: 12, order: 2 }} lg={{ span: 5, order: 1 }} className="left-details">
                <Button onClick={handleBack} className="back-btn">
                  <Back />
                  Back to Search Listings
                </Button>
                <h1>{props?.propertyData?.display_address}</h1>
                <div className="price">{props?.propertyData?.price_qualifier==="POA"?props?.propertyData?.price_qualifier:"£" + props?.propertyData?.price?.toLocaleString()}</div>
                <div className="property-has">
                  <div><Bedroom/> <span>{bedroom}</span></div>
                  <div><Bathroom/><span>{bathroom}</span></div>
                  <div><Reception/><span>{reception}</span></div>
                </div>
                {props?.propertyData?.status !== "Sold" &&
                <div className="mobile-float">

                <Button onClick={() => setShowForm(true)} className="btn secondary">request a viewing</Button>
                    {/* <div className="call-icon" onClick={()=>setShowModal(true)}><CallIcon /></div> */}
                    <a className="call-icon" href={"tel:" + "01225 789333"}><CallIcon /></a>
                </div>
}
{props?.propertyData?.status !== "Sold" &&
                <a className="call" href={"tel:" + "01225 789333"}>or call 01225 789333</a>
}
                <ul className="tabs">
                {props?.propertyData?.images?.length > 0 && (
                  <li><a href="javascript:void(0)" onClick={e => openPropertyImage(e, 0)} className="tab-link">gallery</a></li>
                )}
                  {props?.propertyData?.floorplan?.length > 0 && (
                  <li><a href="javascript:void(0)" onClick={e => openFloorplanImage(e, 0)} className="tab-link">floorplan</a></li>
                  )}
                  {props?.propertyData?.selling_info?.publicBrochureUrl && props?.propertyData?.status !== "Sold" && (
                  <li><a onClick={() => trackerVideo("Download Brochure")} href={props?.propertyData?.selling_info?.publicBrochureUrl}
                  target="_blank"
                  // onClick={e =>
                  //   openBrochureImage(e, 0)
                  // }
                  className="tab-link">brochure</a></li>
                  )}
                  {epcUrl && (
                  <li><a href={epcUrl} target="blank" className="tab-link">epc</a></li>
                  )}
                  {/* {
                     videoUrl&&
                  <li>
                    <div className="video-pop">Video <PlayVideo videourl={videoUrl}></PlayVideo></div> </li>

                    } */}
                   { videoUrl&&
                  <li>
                    <a href="javascript:void(0)" onClick={(e)=>videoHandler(e,0)}>Video </a> </li>

                    }
                    {
                      isPlayVideo&&
                      <PlayVideoNew title={props?.propertyData?.display_address} isPlay={isPlayVideo} show={showVideo} setShow={setShowVideo} setPlay={setPlayVideo} videourl={videoUrl}/>
                    }
                    {props?.propertyData?.search_type == "lettings" && (props?.propertyData?.status == "To Let" || props?.propertyData?.status == "Let Agreed") && (
                        <li><a href={`/why-let-with-us/our-fees`} className="tab-link">Tenant Fees</a></li>
                    )}


                </ul>


              </Col>


              <Col md={{ span: 12, order: 1 }} lg={{ span: 7, order: 2 }}>
                <div className="property-big-img">

                  {props?.propertyData?.images?.length > 0 ? (
                    <picture
                      onClick={e => openPropertyImage(e, 0)}
                    >
                      <ImageTransform
                        imagesources={props?.propertyData?.images[0]?.url}
                        renderer="srcSet"
                        imagename="property.images.detail"
                        attr={{
                          alt:
                            props?.propertyData?.display_address+" - Crisp Cowley"

                        }}
                        imagetransformresult={processedImages}
                        id={props.propertyData.id}
                        testparam={true}
                      />
                    </picture>
                  ) : (
                    <picture>
                        <img src={NoImage} alt="no-img"/>
                    </picture>
                  )}
                  {props?.propertyData?.images &&
                    props?.propertyData?.images?.length > 0 && (
                      <a
                        className="view-more"
                        href="javascript:void(0)"
                        onClick={e => openPropertyImage(e, 0)}
                      >
                        View More
                      </a>
                    )}

                    {
                     videoUrl&&
                     <PlayVideo title={props?.propertyData?.display_address} videourl={videoUrl}></PlayVideo>

                    }

                  {/* {props?.propertyData?.status!=="For Sale"&&
                    <span className={`tag_name`}>{props?.propertyData?.status === "Sale Agreed" ? "UNDER OFFER" :props?.propertyData?.status?.toUpperCase()}</span>

                  } */}

          {props?.propertyData?.status!=="For Sale" ?
            <span className={`tag_name`}>{props?.propertyData?.status === "Sale Agreed" ? "UNDER OFFER" : props?.propertyData?.status?.toUpperCase()}</span>
          : daysLeft < 40 ?
          <span className={`tag_name`}>NEW</span>
          : null
          }
                </div>
              </Col>
            </Row>
            <div ref={myRefless}></div>
            <Row className="d-flex margin-top-80">
            <Col md={{ span: 12, order: 2 }} lg={{ span: 8, order: 1 }}>

                {props?.propertyData?.long_description&&
                <div className={`text-blk ${activeStatus ? active : ""}`}>
                  {activeStatus ? active === 'Read More' ?
                    <ContentBlock Content={props?.propertyData?.long_description.slice(0, 750) + '...'} />
                    : <ContentBlock Content={props?.propertyData?.long_description} />
                    : <ContentBlock Content={props?.propertyData?.long_description} />}
                  {props?.propertyData?.long_description?.length > 750 && <Button className="btn secondary read-more" onClick={handleAccordionClick}>{active}</Button>}

                </div>
                }
                <div className="feature-container">
                  {keyFeatures&&keyFeatures[0]!==""&& (
                  // <p><a href="javascript:void(0)" onClick={() => showFeature(!feature)} className={feature ? "tab-link active" : "tab-link"}>KEY FEATURES</a></p>
                  <p>KEY FEATURES</p>
                  )}
                  {keyFeatures&&keyFeatures[0]!==""&&
                  <div className="feature-list">

                  {keyFeatures?.map((feature,i)=>(
                    <div >
                        <ul>
                          <li>{feature?.replace("*","")}</li>
                        </ul>
                    </div>
                  ))}
                  </div>
                  }
                </div>
            </Col>
              <Col md={{ span: 12, order: 1 }} lg={{ span: 4, order: 2 }}>
                <ul className="big-tabs">
                  {tenure&&
                  <li><div className="tab-title">tenure</div>
                    <div className="tab-data">{capitalize(tenure)}</div>
                  </li>
                    }
                                      {/* <li><div className="tab-title">epc</div>
                    <div className="tab-data">

                      B
                      </div>
                  </li>
                  <li><div className="tab-title">council tax band </div>
                    <div className="tab-data">C</div>
                  </li> */}
                </ul>
              </Col>
            </Row>
            <Row className="d-md-flex gallery d-none">
              {
               initialImg?.map((hit, index) => {
                  let processedImages2 = JSON.stringify({});
                  if (props?.propertyData?.imagetransforms?.images_Transforms) {
                    processedImages2 = props?.propertyData?.imagetransforms.images_Transforms;
                  }

                  return (
                    <Col md="6" className={index === 0 ? "d-lg-block d-sm-none d-none" : index === 1 ? "d-none d-sm-none d-lg-block" : index === 2 ? "d-none d-md-block" : ""}>
                      <div className={index === 0 || index === 1 ? "property-gallery-img" : `property-gallery-img bootom-no ind-${index}`}>
                        <ImageTransform imagesources={hit.url} renderer="srcSet" imagename='property.images.similardetail'
                          attr={{ alt: 'Gallery images - Crisp Cowley', className: '' }}
                          imagetransformresult={processedImages2} id={props?.propertyData?.id} >

                        </ImageTransform>

                      </div>


                    </Col>

                  )
              })}

                {
                    moreImages&&
                <Container>
                <Button onClick={(e)=>showMoreImage(e,0)} className="load-more details-page">View More</Button>
                </Container>
              }
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal className="teamdetails popupForm prop-details" backdrop="static" size="lg" show={showForm} onHide={() => { setShowForm(false) }}>
        <Modal.Header closeButton>
          <Modal.Title>request a viewing</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <BookAViewing  propertyData={props?.propertyData}/>
        </Modal.Body>

      </Modal>

      <Modal className="teamdetails popupForm" backdrop="static" size="lg" show={showModal} onHide={() => { setShowModal(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>Email Us</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PropertyContact propertyAddress={propertyAddress} />
            </Modal.Body>

    </Modal>
{/* Banner image popup */}


      {isOpen && (
        <Lightbox
          mainSrc={propertyLightImages[photoIndex]}
          nextSrc={
            propertyLightImages[
            (photoIndex + 1) % propertyLightImages.length
            ]
          }
          prevSrc={
            propertyLightImages[
            (photoIndex + propertyLightImages.length - 1) %
            propertyLightImages.length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + propertyLightImages.length - 1) %
              propertyLightImages.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
          }
        />
      )}
{/* Banner image popup */}
       {/* Floorplan image popup */}
       {isOpenFloor && (
            <Lightbox
              mainSrc={floorplanLightImages[floorIndex]}
              // nextSrc={
              //   floorplanLightImages[
              //     (floorIndex + 1) % floorplanLightImages.length
              //   ]
              // }
              // prevSrc={
              //   floorplanLightImages[
              //     (floorIndex + floorplanLightImages.length - 1) %
              //       floorplanLightImages.length
              //   ]
              // }
              onCloseRequest={() => setIsOpenFloor(false)}
              onMovePrevRequest={() =>
                setFloorIndex(
                  (floorIndex + floorplanLightImages.length - 1) %
                    floorplanLightImages.length
                )
              }
              onMoveNextRequest={() =>
                setFloorIndex((floorIndex + 1) % floorplanLightImages.length)
              }
            />
          )}
          {/* Floorplan image popup */}


                 {/* Brochure image popup */}
       {isOpenBrochure && (
            <Lightbox
              mainSrc={brochureplanLightImages[brochureIndex]}
              nextSrc={
                brochureplanLightImages[
                  (brochureIndex + 1) % brochureplanLightImages.length
                ]
              }
              prevSrc={
                brochureplanLightImages[
                  (brochureIndex + brochureplanLightImages.length - 1) %
                  brochureplanLightImages.length
                ]
              }
              onCloseRequest={() => setIsOpenBrochure(false)}
              onMovePrevRequest={() =>
                setBrochureIndex(
                  (brochureIndex + brochureplanLightImages.length - 1) %
                  brochureplanLightImages.length
                )
              }
              onMoveNextRequest={() =>
                setBrochureIndex((brochureIndex + 1) % brochureplanLightImages.length)
              }
            />
          )}
          {/* Brochure image popup */}

          {showpopImage && (
        <Lightbox
          mainSrc={showLightImages[photoIndex]}
          nextSrc={
            showLightImages[
            (photoIndex + 1) % showLightImages.length
            ]
          }
          prevSrc={
            showLightImages[
            (photoIndex + showLightImages.length - 1) %
            showLightImages.length
            ]
          }
          onCloseRequest={() => setPopImage(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + showLightImages.length - 1) %
              showLightImages.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % showLightImages.length)
          }
        />
      )}

    </section>
  )
}
export default PropertyDetails
