import React from 'react';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag";
import SEO from "../components/seo"
import { Helmet } from "react-helmet";
import CtaBlock from "../components/CtaBlock/ctaBlock";
import Layout from "../components/layout"
import PropertyDetails from "../components/PropertyDetails/propertyDetails";
import SimilarProperties from "../components/SimilarProperties/similarProperties";
import PropertyDetailsMap from "../components/PropertyDetailsMap/PropertyDetailsMap"
import BrandLogo from "../images/cricow-logo_colour.svg"
import { isMobile } from 'react-device-detect';
import { isSafari, isIOS, isMacOs } from "react-device-detect"
import { navigate } from "@reach/router";

const PROPERTY_DETAILS = gql`
  query GetProperty ($id: String!) {
    properties(where:{id: $id}) {
      id
      search_type
      status
      publish
      department
      display_address
      price
      selling_info
      extra
      price_qualifier
      building
      description
      bedroom
      bathroom
      reception
      floorplan
      long_description
      accomadation_summary
      images
      imagetransforms
      description
      extra
      epc
      selling_info
      letting_info
      address
      latitude
      longitude
      brochure
      slug
      video_tour
      virtual_tour
      created
    }
  }
`;

const PropertyDetailsTemplate = (props) => {

    // 
    const capitalize = (s) => {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
    // 
    // debugger
    //
    const pageurl = typeof window !== 'undefined' ? window.location.href : ''
    let myid_filt = pageurl.split('?');
    let mypageurl = myid_filt[0];
    let property_slug = mypageurl.split('-');
    let id = property_slug[property_slug.length - 1];
    id = id.replace("/", "");

    const { loading, error, data } = useQuery(PROPERTY_DETAILS, {
        variables: { id: id }
    });

    if(data && data?.properties[0]?.publish == false){
      navigate("/404");
    }

    // Loading logo
    if (loading) return (
        <section className={"loader-wrapper"}>
            <div id="loader-wrapper">
                <div id="loader" className="new-loader">
                    <img className="logo-white loader-logo" src={BrandLogo} alt="logo"/>
                </div>
            </div>
        </section>
    );
    // Loading logo
    //

  // Set Popular Search 
  var popularsearch = "Sales_Links";
  if (typeof window !== 'undefined' && window.location.href.indexOf("to-rent") != -1) {
    popularsearch = "Rent_Links";
  }
  // 
  return (
    <Layout layout="without_banner_template">
       <Helmet
          bodyAttributes={{
            class: `templates-property-details-template ${(isSafari === true || isIOS === true || isMacOs === true)? "mac-device":""}`,
          }}
        />
    <div className="property-details">
    {
        data && data.properties && data.properties[0]?.publish && data.properties.length > 0 ?
        (
          data.properties.map((data, i) => {
            var searchaction = data.search_type === "sales"?"for sale":"to let"            
            // var metatile = ""+capitalize(data.building && data.building.length > 0 && data.building[0].replace(/-/g, ' '))+" "+searchaction+" with "+data.bedroom+" bedrooms in "+data.display_address+" at £"+data.price.toLocaleString();
            // var metadesc = "Know the details of "+capitalize(data.building && data.building.length > 0 && data.building[0].replace(/-/g, ' '))+" "+searchaction+" with "+data.bedroom+" bedrooms in "+data.display_address+" at £"+data.price.toLocaleString()+". Book a viewing to get assistance in finding the right "+capitalize(data.building && data.building.length > 0 && data.building[0].replace(/-/g, ' '))+" for you.";
            var metadesc=`Know the details of property ${searchaction} with ${data.bedroom} bedrooms in ${data.display_address} at £${data.price.toLocaleString()}. Book a viewing with Crisp Cowley to get assistance in finding the right property for you.`
            var metatile=`Property ${searchaction} with ${data.bedroom} bedrooms in ${data.display_address} at £${data.price.toLocaleString()}`
            return (
              <React.Fragment key={i}>
                <SEO image={data.images && data.images[0]?.url} title={metatile} description={metadesc} />
                <PropertyDetails propertyData={data} />
                { data?.latitude!==0 && data?.longitude!==0 && data?.latitude && data?.longitude && data?.id !== '659fed58eaa0576e4776c43f' &&(
                  <PropertyDetailsMap
                    lat={data?.latitude}
                    lng={data?.longitude}
                  />
                )}
                {!isMobile&& data?.status !== "Sold" &&
                <CtaBlock propertyData={data}/>
                }
                <SimilarProperties propertyData={data} />
             
              </React.Fragment>
            )
          })
        )
        :
        ''
      }
       
    </div>
 </Layout>
       )
}



export default PropertyDetailsTemplate