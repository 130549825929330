import React from 'react';
import GoogleSingleMap from "../maps/google/single"
import './assets/styles/_index.scss';

const PropertyDetailsMap = (props) => {
    return (
        <section className="property-details-map-wrapper" id="property-details-map">
            <GoogleSingleMap lat={props?.lat} lng={props?.lng} />
        </section>
    )
}

export default PropertyDetailsMap