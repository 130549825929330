import React, { useState, useRef } from "react";
import { Modal, Button } from 'react-bootstrap';
import 'react-modal-video/scss/modal-video.scss';
import { isIOS, isMobile } from "react-device-detect";
import ReactPlayer from 'react-player';
import getVideoId from 'get-video-id';
import './assets/styles/_index.scss';
import $ from "jquery";

const PlayVideoNew = (props) => {
    
   const {show, isPlay,setShow,setPlay}=props
    
    const [fullscreen, setFullscreen] = useState(true);
    const vidRef = useRef(null);

    //    const handleButton=()=>{
    //     setShow(true)
    //     setPlay(true)
    //     setTimeout(()=>{
    //         $(".modal-bg-black").hide();
    //     },1700)
    // }
    const trackerVideo = (event) => {
        if (typeof window !== "undefined"){
        window.dataLayer = window?.dataLayer || [];
        window?.dataLayer?.push({
          'event': 'Video Play Btn',
          'formType': event,
          'formId': 'Video Play',
          'formName': 'Video',
          'formLabel': 'Video'
        });
    }
    }

    if (props.videourl) {
        return (
            <React.Fragment>
                {/* <Button onClick={(e) => handleButton()} className="play-btn"></Button> */}
                <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    dialogClassName="modal-video"
                    aria-labelledby="example-custom-modal-styling-title"
                    backdropClassName="video-backdrop"
                    fullscreen={fullscreen}
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                    {isMobile &&
                    <div className="modal-bg-black">
                    </div>
                    }

                        <ReactPlayer 
                        onEnded={() => {setPlay(false) }}
                         frameborder="0"
                          webkitallowfullscreen
                          mozallowfullscreen
                           allowfullscreen
                            ref={vidRef}
                             url={props.videourl}
                              modestbranding="1"
                               controls={isMobile && isIOS ? true : true}
                                autoplay={true}
                                onPlay={()=>trackerVideo(props.title)}
                                 muted={isMobile && isIOS ? true : false}
                                  playsinline={true}
                                   playing={isPlay} width='100%' height='100%' />
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }

    else if(props.htmlink) {
        return (
            <React.Fragment>
                <Modal
                    show={isPlay}
                    onHide={() => setPlay(false)}
                    dialogClassName="modal-fullscreen modal-video"
                    aria-labelledby="example-custom-modal-styling-title"
                    backdropClassName="video-backdrop"
                    fullscreen={fullscreen}
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <iframe src={props.htmlink} width='100%' height='100%' />
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )        
    }
    else {
        return(
            <empty></empty>
        )
    }
}

export default PlayVideoNew